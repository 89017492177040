body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.player-card {
  background-color: #0B233F;
  color: white;
  margin-bottom: 50px;
  padding-left: 150px;
  padding-right: 150px;
  display: flex;
}

.img-container {
  display: flex;
  flex: 1;
  align-items: flex-end;
  justify-content: space-between;
}

.stats-container {
  display: flex;
  width: 245px;
  margin-bottom: 5px;
}

.stat-category {
  font-size: 90%;
  text-align: center;
}

.stat-num {
  font-size: 170%;
}

.link-no-link {
  color: black;
  text-decoration: none; /* no underline */
}

.home-btn {
  margin-left: 20px;
  margin-right: 20px;
}

.MuiAutocomplete-endAdornment {
  display: none;
}

.MuiAutocomplete-popupIndicator {
  display: none;
}

.autocomplete {
  background-color: white;
  border-radius: 12px;
  width: 500px;
}

.MuiDataGrid-columnHeaders {
  background-color: #0B233F;
  color: white;
}

.MuiDataGrid-columnHeadersInner .MuiSvgIcon-root {
  color: white;
}

.MuiDataGrid-columnHeaderTitle {
  font-weight: 500 !important;

}

.MuiListItem-button:hover {
  background-color: #0B233F !important;
  color: white !important;
  font-weight: 600 !important;
}

.img-div {
  width: 300px; 
  height: 219.23px;
  display: block;
}

.home-link {
  display: none;
}

.index-text {
  font-size: 170%;
  font-weight: bold;
  margin-top: 50px;
  padding-right: 40px;
}

.page-body {
  min-height: calc(100vh - 128px);
}

.page-body-about {
  min-height: calc(100vh - 170px);
}


.footer {
  background-color: #0B233F !important;
  text-align: center;
  position: relative;
  left: 0;
  bottom: 0;

}

.player-years-img {
  width: 700px;
}

.season-graph-img {
  width: 1000px;
}

.footer-text {
margin: 0 auto !important; }

.graph-title {
  margin-left: 30px;
}

.about {
  margin-left: 20%;
  margin-right: 20%;
  text-align: justify;
  margin-bottom: 50px;
}

.table-comp {
  width: 400px !important;
  margin: 0 auto;
  margin-top: 30px;
}

.tables {
  width: 400px !important;
}

@media only screen and (max-width: 600px) {
  .graph-title {
    margin-left: 0;
  }

  .player-card {
    padding-top: 20px;
    background-color: #0B233F;
    color: white;
    margin-bottom: 50px;
    padding-left: 30px;
    padding-right: 30px;
    display: block;
  }

  .season-graph-img {
    width: 95%;
  }

  .player-years-img {
    width: 90%;
  }

  .rpdef {
    display: none;
  }

  .autocomplete {
    width: 250px;
  }

  .player-name {
    text-align: center;
  }

  .stats-container {
    margin: 0 auto;
  }

  .player-text {
    margin: 0 auto;
    margin-bottom: 30px;
  }

  .image {
    margin: 0 auto;
  }

  .img-container {
    display: block;
  }

  .img-div {
    display: flex;
    margin: 0 auto;
  }

  .home-link {
    display: block;
  }

  .index-text {
    font-size: 170%;
    font-weight: bold;
    margin-top: 20px;
    padding-right: 0;
    margin-bottom: 20px;
    text-align: justify;
  }

  .about {
    margin-left: 10%;
    margin-right: 10%;
    text-align: justify;
  }

  .table-comp {
    width: 100% !important;
  }
}